export const initialState = {
    term: "",
    followup: "",
    conversationHistory:[],
    loading: false,
    streaming: false,
    nextQuestion:"",
    nextQuestionLoading:false,
    chatMode: false,
    editing: false,
    user:null,
    products: [],
    unsignedCount:"0",
    subscription:null,
    loginLoading: false,
    paymentInfo: null,
    meter: 0,
    headlines:[],
    webaccess: false,
    autoplayAllowed: false,
    mode: "search",
    dob:"",
    tob:"",
    geo: {lat:0, lon:0},
    city:"",
    horoscope: null,
    userCred: null,
    tier: null,
    uid: "",
    entity:"default",
    phoneHash: "",
    test: 0,
    category:"basic",
    gender: !!localStorage.getItem("gender") ? localStorage.getItem("gender"): "male",
    app_category: ""
//     app_category: "All"
};

//app_category: "Personal Reports"
export const actionTypes = {
    SET_SEARCH_TERM: "SET_SEARCH_TERM",
    SET_USER_CRED: "SET_USER_CRED", 
    SET_APP_CATEGORY: "SET_APP_CATEGORY",
    SET_GENDER: "SET_GENDER",
    SET_TIER: "SET_TIER",
    SET_UID: "SET_UID", 
    SET_AUTOPLAY_ALLOWED: "SET_AUTOPLAY_ALLOWED",
    UPDATE_CONVERSATION_HISTORY: "UPDATE_CONVERSATION_HISTORY",
    SET_FOLLOWUP_TERM: "SET_FOLLOWUP_TERM",
    SET_STREAMING:"SET_STREAMING",
    SET_LOADING:"SET_LOADING",
    SET_NEXT_Q:"SET_NEXT_Q",
    SET_NEXT_Q_LOADING:"SET_NEXT_Q_LOADING",
    SET_CHAT_MODE: "SET_CHAT_MODE",
    RESET_STATE: "RESET_STATE",
    SET_EDITING: "SET_EDITING",
    SET_USER: "SET_USER",
    SET_STRIPE_CUSTOMER: "SET_STRIPE_CUSTOMER",
    ENRICH_STRIPE_CUSTOMER: "ENRICH_STRIPE_CUSTOMER",
    SET_STRIPE_SUBSCRIPTION: "SET_STRIPE_SUBSCRIPTION",
    SET_LOGIN_LOADING: "SET_LOGIN_LOADING",
    SET_PAYMENT_INFO: "SET_PAYMENT_INFO",
    UPDATE_METER:"UPDATE_METER",
    UPDATE_HEADLINES: "UPDATE_HEADLINES",
    SET_WEB_ACCESS:"SET_WEB_ACCESS",
    SET_MODE:"SET_MODE",
    SET_USER_DETAIL: "SET_USER_DETAIL",
    SET_USER_DOB:"SET_USER_DOB",
    SET_USER_TOB:"SET_USER_TOB",
    SET_USER_GEO:"SET_USER_GEO",
    SET_USER_CITY:"SET_USER_CITY",
    SET_HOROSCOPE: "SET_HOROSCOPE",
    SET_ENTITY: "SET_ENTITY",
    SET_PHONE_HASH: "SET_PHONE_HASH",
    INCR_TEST: "INCR_TEST",
    SET_TEST: "SET_TEST",
    SET_CATEGORY: "SET_CATEGORY"
}

const reducer = (state, action) => {


    switch(action.type) {
        case actionTypes.INCR_TEST:
                return {...state, test: state.test+1}
        case actionTypes.SET_APP_CATEGORY:
                return {...state, app_category: action.app_category}
        case actionTypes.SET_TEST:
                return {...state, test: action.test}
        case actionTypes.SET_USER_CRED:
                localStorage.setItem('userCred', JSON.stringify(action.userCred)); 
                return {...state, userCred: action.userCred}
        case actionTypes.SET_GENDER:
                return {...state, gender: action.gender}
        case actionTypes.SET_TIER:
                return {...state, tier: action.tier}
        case actionTypes.SET_ENTITY:
                return {...state, entity: action.entity}
        case actionTypes.SET_CATEGORY:
                return {...state, category: action.category}
        case actionTypes.SET_UID:
                localStorage.setItem('uid', JSON.stringify(action.uid)); 
                return {...state, uid: action.uid};
        case actionTypes.SET_USER_DOB:
                return {...state, dob: action.dob};
        case actionTypes.SET_PHONE_HASH:
                return {...state, phoneHash: action.phoneHash}
        case actionTypes.SET_HOROSCOPE:
                return {...state, horoscope: action.horoscope};
        case actionTypes.SET_USER_TOB:
                return {...state, tob: action.tob};
        case actionTypes.SET_USER_GEO:
                return {...state, geo: action.geo};
        case actionTypes.SET_USER_CITY:
                return {...state, city: action.city}
        case actionTypes.SET_USER_DETAIL:               
                return {...state, userDetail: action.userDetail}
        case actionTypes.SET_MODE:
                return {...state, mode: action.mode}
        case actionTypes.SET_AUTOPLAY_ALLOWED:
                return {...state, autoplayAllowed: action.autoplayAllowed }
        case actionTypes.SET_PAYMENT_INFO:
                return {...state, paymentInfo: action.paymentInfo}
        case actionTypes.SET_WEB_ACCESS:
                return {...state, webaccess: action.webaccess}
        case actionTypes.SET_USER:
                localStorage.setItem('user', JSON.stringify(action.user)); 
                return {...state, user: action.user}
        case actionTypes.UPDATE_HEADLINES:
                return {...state, headlines: action.headlines}
        case actionTypes.SET_LOGIN_LOADING:
                return {...state, loginLoading: action.loginLoading}
        case actionTypes.SET_STRIPE_SUBSCRIPTION:
                return {...state, subscription: action.subscription}
        case actionTypes.SET_EDITING:
                return {...state}
        case actionTypes.SET_STRIPE_CUSTOMER:
                return {...state, stripeCustomer: action.stripeCustomer }
        case actionTypes.ENRICH_STRIPE_CUSTOMER:
                return {...state, stripeCustomer:{...state.stripeCustomer, subscriptions:action.subscriptions}}
        case actionTypes.RESET_STATE:
                return {...initialState, app_category: state.app_category, gender: state.gender, city:state.city, user:state.user, webaccess: state.webaccess, headlines: state.headlines, userCred: state.userCred, uid: state.uid, dob: state.dob, tob: state.tob, geo: state.geo, horoscope: state.horoscope};
        case actionTypes.SET_STREAMING:
                const update = {...state, streaming: action.streaming}; 
                return update;
        case actionTypes.SET_CHAT_MODE:
                const newState = {...state, chatMode: action.chatMode}; 
                return newState;
        case actionTypes.SET_SEARCH_TERM: 
            
            const updatedState = {
                ...state,
                term: action.term,
                followup: "", 
                 loading: true,  
                nextQuestion:"", 
                SET_CHAT_MODE: false,
                conversationHistory: [{"role":"user", "content": action.term}] 
            };
            return updatedState;
        case actionTypes.SET_NEXT_Q:
            return {...state,
            nextQuestion: action.next, 
        loading: false}
        case actionTypes.SET_NEXT_Q_LOADING:
            return {...state, nextQuestionLoading: action.nextQuestionLoading, editing: false}
        case actionTypes.SET_FOLLOWUP_TERM:
            //console.log("Set term called!!!");
                return {
                    ...state, 
                    followup: action.followup,
                    loading: true,
                    nextQuestion:"",
                    conversationHistory: [...state.conversationHistory, {"role":"user", "content": action.followup}]
                }
        case actionTypes.UPDATE_CONVERSATION_HISTORY: //conversation history filled, 
                {
                   // console.log(`Updating Conversation History state to:`);
                let res = {
                    ...state,
                    term:"",
                    followup:"",
                    conversationHistory: action.enrichedConversationHistory
                };
                return res;
                }
        case actionTypes.SET_LOADING:
                return {
                    ...state,
                    loading: action.loading
                }
        default: 
            return state;
    }
}

export default reducer;
