import React, { useState, useRef, useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// Import your spinner component
import AtomicSpinner from 'atomic-spinner';

const audioFiles = [
  'https://astrosky.blob.core.windows.net/astrosky/Aries.mp3',
  // Add more audio URLs here if needed
];

const WeeklyHoroscopeView = () => {
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const audioRefs = useRef([]);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 2000); // Adjust the time as needed
    return () => clearTimeout(timer);
  }, []);

  const handlePlay = (index) => {
    if (currentPlaying === index) {
      audioRefs.current[index]?.audio.current.pause();
      audioRefs.current[index].audio.current.currentTime = 0;
      setCurrentPlaying(null);
    } else {
      if (currentPlaying !== null) {
        audioRefs.current[currentPlaying]?.audio.current.pause();
        audioRefs.current[currentPlaying].audio.current.currentTime = 0;
      }
      audioRefs.current[index]?.audio.current.play();
      setCurrentPlaying(index);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {loading ? (
        <AtomicSpinner />
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {audioFiles.map((audioSrc, index) => (
            <div key={index} style={{ margin: '10px' }}>
              <img
                style={{ width: '85%', borderRadius: '15px', cursor: 'pointer' }}
                src="https://astrosky.blob.core.windows.net/astrosky/Weekly-Card.png"
                alt={`Card ${index + 1}`}
                onClick={() => handlePlay(index)}
              />
              <AudioPlayer
                ref={(el) => (audioRefs.current[index] = el)}
                autoPlay={false}
                src={audioSrc}
                onEnded={() => setCurrentPlaying(null)}
                style={{ marginTop: '10px' }}
                showSkipControls={false}
                showJumpControls={false}
                controls
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeeklyHoroscopeView;



