import React, { useState, useEffect, useRef } from 'react';
import {TextField, Button, Typography, CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { getUrl } from '../../helpers/envUrls';
import { auth, signInWithCustomToken } from '../../config/firebase';
import { useStateValue } from '../../statemanagement/StateProvider';
import { actionTypes } from '../../statemanagement/reducer';
import { useNavigate } from 'react-router-dom';
import './userLogin.css';
import Logo from '../logo/logo';

const UserLogin = () => {
  const navigate = useNavigate();
  const [{}, dispatch] = useStateValue();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [requestId, setRequestId] = useState('');
  const [otpTimer, setOtpTimer] = useState(60);
  const otpInputRef = useRef(null);

  useEffect(() => {
    if (isOtpSent && otpInputRef.current) {
      otpInputRef.current.focus(); 
    }
  }, [isOtpSent]);

  useEffect(() => {
    if (isOtpSent && otpTimer > 0) {
      const timer = setTimeout(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [otpTimer, isOtpSent]);

  const handleResendOtp = async () => {
    setOtpTimer(60);
    handleGetOtp(); 
  };

  const handleGetOtp = async () => {
    if (!phoneNumber) {
      alert('Please enter your phone number.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${getUrl()}/send-mobile-otp`, { phoneNumber });
      setRequestId(response.data.requestId);
      if (response.status === 200) {
        setIsOtpSent(true);
        setOtpTimer(60); 
        window.gtag('event', 'Create_OTP');
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('An error occurred while sending OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${getUrl()}/verify-mobile-otp`, { uniqueRequestId: requestId, otp, phoneNumber });
      if (response.status === 200) {
        const { token, uid } = response.data;
        await signInWithCustomToken(auth, token);
        dispatch({
          type: actionTypes.SET_USER_CRED,
          userCred: { uid, accessToken: token },
        });
        dispatch({
          type: actionTypes.SET_USER,
          user: { uid, phone: phoneNumber },
        });
        dispatch({
          type: actionTypes.SET_UID,
          uid,
        });
        navigate("/edit");
        window.gtag('event', 'Verify_OTP');
        console.log(uid, " uid");
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('An error occurred while verifying OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-dialog-container">
      <div className="logo-section">
        <Logo />
      </div>
      <div className="login-dialog-content">
        <Typography variant="h5" className="title">Welcome to AstroSky!</Typography>
        {!isOtpSent ? (
          <>
          <div className="phone-input-wrapper">
            <PhoneInput
              defaultCountry="IN"  
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Phone number"
              className="phone-input" 
            />
          </div>
            <Button 
              variant="contained" 
              onClick={handleGetOtp} 
              disabled={loading} 
              className="continue-button"
            >
              {loading ? <CircularProgress size={24} /> : 'Continue'}
            </Button>
          </>
        ) : (
          <>
            <TextField
              className="otp-input-field" 
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 4 }}
              inputRef={otpInputRef}
            />
            {otpTimer > 0 ? (
              <Typography
                variant="body2"
                className="otp-timer-message" 
              >
                Resend OTP in {otpTimer} seconds
              </Typography>
            ) : (
              <Button variant="text" className="resend-otp-button" onClick={handleResendOtp}>
                Resend OTP
              </Button>
            )}
            <Button 
              variant="contained" 
              onClick={handleVerifyOtp} 
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Verify OTP'}
            </Button>
          </>
        )}
        {error && <Typography color="error" className="title">{error}</Typography>}
      </div>
    </div>
  );
};

export default UserLogin;
