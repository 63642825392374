import { Button, Rating } from "@mui/material";
import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { useNavigate } from "react-router-dom";
import "./Entity.css";


const Entity = ({ entityName, entityCode, entityDescription, entityImageSrc, app_category,  entityRating, sampleQuestions,category="basic", chatDisplay="Chat", orders }) => {

    const [{ userCred, entity, horoscope, dob, tob, city }, dispatch] = useStateValue();
    const navigate = useNavigate();

    // const skill = entityDescription.split(",").map((entitySkill)=>{

    //     return (entitySkill.trim() === "Top Rated" || entitySkill.trim() === "Marriage" || entitySkill.trim() === "Wealth Horoscope" ||entitySkill.trim() === "Personality" || entitySkill.trim() === "Numerology" || entitySkill.trim() === "Love" ||  entitySkill.trim() === "General" ||  entitySkill.trim() === "Career" || entitySkill.trim() === "Vastu" || entitySkill.trim() === "Motivational Coach" || entitySkill.trim() === "Feng Shui Correction" ) ? 
    //     (<p style={{background:"black", color:"white", opacity:"0.8"}} className="skillStyle">
    //     {entitySkill} </p>): 
    //     (<p className="skillStyle">
    //     {entitySkill}
    // </p>)
    // });

    const clickEntity  = ()=>{
        if(!dob || !tob || !city){
            console.log(`${JSON.stringify(horoscope)}`);
            console.log(`${dob}`);
            console.log(`${tob}`);
            console.log(`${city}`);
            navigate("/edit");
            return;
        }

        window.gtag('event', entityCode);
        console.log(`Event: ${entityCode}`);

       if(entityCode != "guruji"){
        dispatch({type: actionTypes.SET_ENTITY, entity:entityCode})
        }
        
        dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
        dispatch({ type: actionTypes.SET_LOADING, loading: true });
       
        navigate("/query");
    }

    return (<div className="entityContainer">
      
             <img  className="entityImage" 
            src={entityImageSrc} alt={entityName} onClick={((_)=> clickEntity())}  />


        <br /><br />

        <p style={{ fontWeight: "500", fontSize: "1.3rem", marginBottom: "5px" }} onClick={((_)=> clickEntity())}  >{entityName} </p>

        {/* <div style={{display:"flex", flexDirection:"row", alignItems:"center", "justifyContent":"center"}}>{skill}</div> */}
     
        <div style={{color:"gray", display:"flex", flexDirection:"row", alignItems:"center", "justifyContent":"center", marginTop:"20px", marginBottom:"20px", fontWeight:"400", fontSize:"1rem"}} onClick={((_)=> clickEntity())} >{entityDescription}</div>
  

       { category == "basic" &&  <Button className="discoverChatStyle" onClick={(e) => {

            if(!horoscope  || !dob || !tob || !city){
                navigate("/edit");
                return;
            }

            window.gtag('event', entityCode);
          //  console.log(`Event: ${entityCode}`);

           if(entityCode != "guruji"){
            dispatch({type: actionTypes.SET_ENTITY, entity:entityCode})
            }
            
            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
            dispatch({ type: actionTypes.SET_LOADING, loading: true });
           
            navigate("/query");
        }}>{chatDisplay}</Button> }

        <br/> <br/>
       

        { category == "ultra" &&  <Button className="discoverChatStyle" onClick={(e) => {

            if(!horoscope){
                navigate("/edit");
                return;
            }

            dispatch({type: actionTypes.SET_CATEGORY, category:"celeb"});
            dispatch({type: actionTypes.SET_ENTITY, entity:entityCode});
            dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AstroSky" });
            dispatch({ type: actionTypes.SET_LOADING, loading: true });
            window.gtag('event', entityCode);
            navigate("/query");
        }}>Chat</Button> }

        {/* { app_category == "Career" &&  <Button className="discoverChatStyle" onClick={(e) => {

        navigate("/reports");
        }}>Personal Report</Button> } */}

{/* <Button startIcon={<img src="https://astrosky.blob.core.windows.net/astrosky/report.png" alt="Constellation" style={{marginLeft:"8px",  width:"35px", height:"35px", textDecoration: 'none' }} />}
                    style={{
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        window.gtag('event', 'Refine_PA', {
                            event_category: 'Refine_PA',
                            event_label: "Report", 
                        });
                        //location("/reports");
                    }}></Button> */}
        
        {/* <br />  */}
        
        {/* <br /> */}

<div style={{display:"flex", justifyContent:"center", alignItems:"end"}}>
<Rating
            sx={{marginTop:"10px !important", marginRight:"10px"}}
            name="simple-controlled"
            value={entityRating}
            precision={0.5}
            onChange={(event, newValue) => {
            }}

        />
        <p style={{marginLeft:"10px", color:"gray", fontSize:"1rem", fontWeight:"400"}}> {orders} orders</p>
</div> <br/>
        
        
        {/* <br /> <br />
        <div style={{color:"gray", fontSize:"1rem"}}>
        5000 orders
        </div> <br/> */}

        {/* {<QuestionsDisplay sampleQuestions={sampleQuestions} entityCode={entityCode} dispatch={dispatch} navigate={navigate} horoscope={horoscope} />} */}
        {/* <HorizontalScroll/> */}
        
        </div>);
}

export default Entity;