import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { actionTypes } from '../statemanagement/reducer';

const HorizontalScrollButtons = ({ app_category, dispatch, horoscope }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const checkScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    checkScrollButtons();
  }, []);

  const buttonStyles = {
    margin: '0 10px',
    textWrap:"nowrap",
    textTransform: "none",
    '&.MuiButton-root:active': {
      boxShadow: 'none',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
  };

  const getView = () => {
    const baseViews = ['All', 'Marriage Predictions', 'Career Predictions', 'Love Predictions', 'Life Coach', 'Wealth', 'Live', 'Numerology', 'Vastu', 'Feng Shui'];
    //const baseViews = ['All', 'Marriage Predictions', 'Career Predictions', 'Love Predictions', 'Wealth Predictions', 'Personal Reports', 'Live Predictions', 'Numerology', 'Vastu', 'Feng Shui'];
    // if (horoscope) {
    //     baseViews.splice(2, 0, 'Weekly Horoscope'); // Insert at index 2
    // }
    return baseViews;
};


  const getLinkPath = (text) => {
    switch (text) {
      case 'Love Predictions':
        return '/love';
      case 'Personal Reports':
        return '/personal-reports';
      case 'Career Predictions':
        return '/career';
      case 'Live':
        return '/live-predictions';
      case 'Numerology':
        return '/numerology';
      case 'Vastu':
        return '/vastu';
      case 'Feng Shui':
        return '/feng-shui'; 
      case 'Wealth Predictions':
        return '/wealth';
      case 'Marriage Predictions':
        return '/marriage';
      case 'New':
        return '/new-entities'
      case 'All':
        return '/'; 
      default:
        return '/'; 
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: "5%" }}>
      {showLeftArrow && (
        <IconButton onClick={() => scroll(-200)} sx={{ visibility: showLeftArrow ? 'visible' : 'hidden' }}>
          <ArrowBackIosIcon sx={{ color: "black !important" }} />
        </IconButton>
      )}
      <Box
        ref={scrollRef}
        onScroll={checkScrollButtons}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Edge
        }}
      >
        {getView().map((text, index) => {
          //const min = text === 'All' ? "60px" : "max-content";
          const linkPath = getLinkPath(text);

          return (
            <React.Fragment key={index}>
              {linkPath ? (
                <Link to={linkPath} style={{ textDecoration: 'none' }}>
                  <Button
                    onClick={() => dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: text })}
                    sx={{
                     ...buttonStyles,
                      background: app_category === text ? "black !important" : "whitesmoke",
                      color: app_category === text ? "white" : "#0F0F0F !important",
                      borderRadius: "11px"
                    }}
                  >
                    {text}
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={() => dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: text })}
                  sx={{
                    ...buttonStyles,
                //n    minWidth: min,
                    background: app_category === text ? "black !important" : "whitesmoke",
                    color: app_category === text ? "white" : "#0F0F0F !important",
                    borderRadius: "11px",
                    
                  }}
                >
                  {text}
                </Button>
              )}
            </React.Fragment>
          );
        })}
      </Box>
      {showRightArrow && (
        <IconButton onClick={() => scroll(200)} sx={{ visibility: showRightArrow ? 'visible' : 'hidden' }}>
          <ArrowForwardIosIcon sx={{ color: "black !important" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default HorizontalScrollButtons;






// import React, { useRef, useEffect, useState } from 'react';
// import { Box, Button, IconButton } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { actionTypes } from '../statemanagement/reducer';

// const HorizontalScrollButtons = ({ app_category, dispatch }) => {
//   const scrollRef = useRef(null);
//   const buttonRefs = useRef([]); // To store references to all buttons
//   const [showLeftArrow, setShowLeftArrow] = useState(false);
//   const [showRightArrow, setShowRightArrow] = useState(true);

//   const checkScrollButtons = () => {
//     if (scrollRef.current) {
//       const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
//       setShowLeftArrow(scrollLeft > 0);
//       setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
//     }
//   };

//   const scroll = (scrollOffset) => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollLeft += scrollOffset;
//     }
//   };

//   useEffect(() => {
//     checkScrollButtons();
//   }, []);

//   useEffect(() => {
//     console.log(`App Category is changed to: ${app_category}`);

//     // Scroll to the selected category button
//     const selectedIndex = getView(app_category).indexOf(app_category);
//     if (buttonRefs.current[selectedIndex]) {
//       const button = buttonRefs.current[selectedIndex];
//       const offsetLeft = button.offsetLeft;
//       const offsetWidth = button.offsetWidth;

//       scrollRef.current.scrollTo({
//         left: offsetLeft - (scrollRef.current.clientWidth / 2 - offsetWidth / 2),
//         behavior: 'smooth',
//       });
//     }
//   }, [app_category]);

//   const buttonStyles = {
//     margin: '0 8px',
//     textTransform: "none",
//     '&.MuiButton-root:active': {
//       boxShadow: 'none',
//     },
//     '&.Mui-focusVisible': {
//       boxShadow: 'none',
//     },
//   };

//   const getView = (app_category) => {
//     const categories = ['All', 'New', 'Love', 'Personal Reports', 'Live Prediction', 'Career','Numerology', 'Vastu', 'Wealth'];

//     if(app_category === "All"){
//       return categories;
//     }
//     const index = categories.indexOf(app_category);
//     if (index > -1) {
//         categories.splice(index, 1); 
//     }

//     categories.splice(1, 0, app_category);

//     return categories;
//   };

//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center' }}>
//       {showLeftArrow && (
//         <IconButton onClick={() => scroll(-200)} sx={{ visibility: showLeftArrow ? 'visible' : 'hidden' }}>
//           <ArrowBackIosIcon />
//         </IconButton>
//       )}
//       <Box
//         ref={scrollRef}
//         onScroll={checkScrollButtons}
//         sx={{
//           display: 'flex',
//           overflowX: 'auto',
//           scrollbarWidth: 'none', // Hide scrollbar for Firefox
//           '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Edge
//         }}
//       >
//         {getView(app_category).map((text, index) => {
//           const min = text === 'All' ? "60px": "fit-content";

//           return (
//             <Button
//               ref={el => buttonRefs.current[index] = el} // Store button references
//               onClick={() => {
//                 dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: text });
//               }}
//               key={index}
//               sx={{
//                 ...buttonStyles,
//                 minWidth:min,
//                 background: app_category === text ? "black !important" : "white !important",
//                 color: app_category === text ? "white" : "black !important",
//               }}
//             >
//               {text}
//             </Button>
//           );
//         })}
//       </Box>
//       {showRightArrow && (
//         <IconButton onClick={() => scroll(200)} sx={{ visibility: showRightArrow ? 'visible' : 'hidden' }}>
//           <ArrowForwardIosIcon />
//         </IconButton>
//       )}
//     </Box>
//   );
// };

// export default HorizontalScrollButtons;
