import React, { useEffect, useState } from 'react';
import "./BasicChat.css";
import { useStateValue } from '../statemanagement/StateProvider';
import Disclaimer from '../components/Disclaimer';
import UseBirthDetails from './UseBirthDetails';
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import { getUrl } from '../helpers/envUrls';
import ReportCard from './ReportCard';
import CircularWithValueLabel from './CircularProgressWithLabel';
import { TypeAnimation } from 'react-type-animation';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ReportView = () => {

    UseBirthDetails();
    const [{ dob, tob, city, horoscope }, dispatch] = useStateValue();
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    //[userEmail, setUserEmail]

    useEffect(() => {

        dispatch({ type: actionTypes.RESET_STATE });
        dispatch({ type: actionTypes.UPDATE_HEADLINES, headlines: [] });

    }, [])

    useEffect(() => {

        const getEntities = async () => {
            const entityJSON = await axios.get(`${getUrl()}/entities`);
            setEntities(entityJSON.data);
        }

        getEntities();

    }, [])

   

    return (

<div>
<div style={{textAlign:"center"}}>
<p style={{ marginTop: "8%", marginBottom:"2%", fontSize: "1.2rem", fontWeight: "400", lineHeight: "2.1rem", display: "inline-block", color: "gray" }}>Handcrafted Predictions & Reports</p>
</div>

<br/> 
        <div style={{ marginTop: "0%" }}>
            <div style={{ textAlign: "center" }}>

                {/* {!!horoscope && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />} */}

                {/* {true && (
                    <div>
                        <br/><br/>
                        
                        <TypeAnimation sequence={[
                                                `Your Order is being placed, Kindly wait..`,
                                            ]} wrapper="span" speed={10} style={{ fontSize: "1.3em", fontWeight:"450", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401" }} repeat={0} />
                        <br/><br/> <br/>
                        <CircularWithValueLabel />
                        <br/><br/>
                    </div>
                    )} */}

                    {loading && <SkeletonTheme baseColor="rgba(128, 128, 128, 0.2)" >
    <p>
      <Skeleton count={10} />
    </p>
  </SkeletonTheme> }

{!loading && 
<>

{/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/vidya.jpeg"}
                    entityName={"Get Career Horoscope"}
                    sampleReport={"/Career.pdf"}
                    reportType={"Career"}
                    entityDescription={"Career Report, Best Seller"}
                    entityRating={5} ppm={3} 
                    loading={loading}
                    setLoading={setLoading}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, Raj Yogas in your Horoscope, 5,000 year old Vedic Predictions for you, Career Overview, Current Dasha Predictions, Nakshatra Career Analysis ✧˖°,  10th House Analysis"} 
                    /> */}

<ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/entity-002.webp"}
                    entityName={"Get Career Horoscope"}
                    sampleReport={"/Career.pdf"}
                    reportType={"Career"}
                    entityDescription={"Career Report, Top Rated"}
                    entityRating={5} ppm={3} 
                    loading={loading}
                    setLoading={setLoading}
                    entityCode={"sunny"}
                    samepleQuestions={"Handcrafted for you by Legendary Astrologer, Promotion Prospects, Dream Job Prospects, Based on 5,000 year old Vedic Predictions, Raj Yogas in your Horoscope, Career Overview, Current Dasha Predictions, Nakshatra Career Analysis ✧˖°,  10th House Analysis"} 
                    />

{/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Ekta.jpg"}
                    sampleReport={"/Love.pdf"}
                    entityName={"Get Love Horoscope"}
                    reportType={"Love"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Love Horoscope, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, 7th House-House-Rashi Analysis, D9 (Navamsha/Partner) Chart Analysis, Will my Partner be Handsome?, Will my Partner be Rich/Wealthy?, Will my Partner be Loyal?, Will my Partner ever Cheat?, More.."} 
                    /> */}

{/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Ekta.jpg"}
                    sampleReport={"/Passion.pdf"}
                    entityName={"Passion & Attraction Report"}
                    reportType={"Passion"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Passion & Intimacy, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, Will there be Passion in our Relationship?, Will there be Physical Attraction between us?, Will my Partner Love me Deeply?, Will my Partner be Passionate?, Will my Partner be Intimate?, More.."} 
                    /> */}

                    <br/><br/>

                    
                    <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />

                    <br/> <br/>

{/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Rekha.jpg"}
                    sampleReport={"/Love.pdf"}
                    entityName={"Get Love Horoscope"}
                    reportType={"Love"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Love Horoscope, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, 7th House-House-Rashi Analysis, D9 (Navamsha/Partner) Chart Analysis, Will my Partner be Handsome?, Will my Partner be Rich/Wealthy?, Will my Partner be Loyal?, Will my Partner ever Cheat?, More.."} 
                    /> */}

{/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Rekha.jpg"}
                    sampleReport={"/Passion.pdf"}
                    entityName={"Passion & Attraction Report"}
                    reportType={"Passion"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Passion & Intimacy, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Handcrafted for you by Legendary Astrologer, Created with Legendary Astrologers, Will there be Passion in our Relationship?, Will there be Physical Attraction between us?, Will my Partner Love me Deeply?, Will my Partner be Passionate?, Will my Partner be Intimate?, More.."} 
                    /> */}
                     <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Ekta.jpg"}
                    sampleReport={"/Love.pdf"}
                    entityName={"Get Love Horoscope"}
                    reportType={"Love"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Love Horoscope, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Handcrafted by Legendary Astrologer, Dream Spouse Prospects, 7th House-House-Rashi Analysis, D9 (Navamsha/Partner) Chart Analysis, Will my Partner be Handsome?, Will my Partner be Rich/Wealthy?, Will my Partner be Loyal?, Will my Partner ever Cheat?, More.."} 
                    />
                    <br/><br/>

                    
                    <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />

                    <br/> <br/>

                    {/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Rekha.jpg"}
                    sampleReport={"/Love.pdf"}
                    entityName={"Get Love Horoscope"}
                    reportType={"Love"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Love Horoscope, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, 7th House-House-Rashi Analysis, D9 (Navamsha/Partner) Chart Analysis, Will my Partner be Handsome?, Will my Partner be Rich/Wealthy?, Will my Partner be Loyal?, Will my Partner ever Cheat?, More.."} 
                    /> */}


                    {/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/entity-002.webp"}
                    entityName={"Get Career Horoscope"}
                    sampleReport={"/Career.pdf"}
                    reportType={"Career"}
                    entityDescription={"Career Report, Best Seller"}
                    entityRating={5} ppm={3} 
                    loading={loading}
                    setLoading={setLoading}
                    entityCode={"sunny"}
                    samepleQuestions={"Created with Legendary Astrologers, Raj Yogas in your Horoscope, 5,000 year old Vedic Predictions for you, Career Overview, Current Dasha Predictions, Nakshatra Career Analysis ✧˖°,  10th House Analysis"} 
                    /> */}
                    {/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Ekta.jpg"}
                    sampleReport={"/Love.pdf"}
                    entityName={"Get Love Horoscope"}
                    reportType={"Love"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Love Horoscope, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Handcrafted for you by Legendary Astrologer, 7th House-House-Rashi Analysis, D9 (Navamsha/Partner) Chart Analysis, Dream Spouse Prospects, Will my Partner be Handsome?, Will my Partner be Rich/Wealthy?, Will my Partner be Loyal?, Will my Partner ever Cheat?, More.."} 
                    /> */}
                    <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Rekha.jpg"}
                    sampleReport={"/Passion.pdf"}
                    entityName={"Passion & Attraction Report"}
                    reportType={"Passion"}
                    loading={loading}
                    setLoading={setLoading}
                    entityDescription={"Passion & Intimacy, Top Rated"}
                    entityRating={5} ppm={3}
                    entityCode={"sunny"}
                    samepleQuestions={"Handcrafted by Legendary Astrologer, Created with Legendary Astrologers, Will there be Passion in our Relationship?, Will there be Physical Attraction between us?, Will my Partner Love me Deeply?, Will my Partner be Passionate?, Will my Partner be Intimate?, More.."} 
                    />
                    <br/> <br/>
                    <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />
                    <br/><br/> <br/>

                    

                {/* <ReportCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/entity-002.webp"}
                    entityName={"In-Depth Career Report"}
                    sampleReport={"/Career.pdf"}
                    reportType={"CareerIndepth"}
                    entityDescription={"In-Depth Career Report, Top Rated"}
                    entityRating={5} ppm={3} 
                    loading={loading}
                    setLoading={setLoading}
                    entityCode={"sunny"}
                    samepleQuestions={"Individually Hand-Crafted, Created with Legendary Astrologers, All Yogas in your Horoscope, 5,000 year old Vedic Predictions for you, 1,3,6 Month Career Predictions, Multi-year Predictions, बृहत् पराशर Success & Fame Predictions, Comprehensive Career Overview, Created with Legendary Astrologers, Doshas & Remedies for Grand Career Success, Detailed Nakshatra Career Analysis ✧˖°, Degree/Pada Level Precision, Detailed 10th House Analysis, Lagna, Navamsha Chart Analysis, D10(Profession) Chart Analysis, More ..."} 
                    /> */}



               
                <br/> <br/>
                </>}
            </div>
        </div>
        </div>


    );
};

export default ReportView;
