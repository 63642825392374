import React, { useEffect, useState } from 'react';
import Header from './Header';
import AppFooter from './AppFooter';
import "./BasicChat.css";
import { useStateValue } from '../statemanagement/StateProvider';
import Disclaimer from '../components/Disclaimer';
import UseBirthDetails from './UseBirthDetails';
import BirthDetailsDisplayComponent from '../components/BirthDetailsDisplayComponent';
import Entity from './Entity';
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import LiveDashas from './LiveDashas';
import { getUrl } from '../helpers/envUrls';
import HorizontalScrollButtons from '../components/HorizontalScrollButtons';
import { TypeAnimation } from 'react-type-animation';
import ReportView from './ReportView';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import WeeklyHoroscopeView from './WeeklyHoroscopeView';

const Discover = () => {

    // UseBirthDetails();
    const [{ userCred, dob, tob, city, horoscope, app_category, gender }, dispatch] = useStateValue();
    //useNotifications();
    //usePushNotifications();
    const [entities, setEntities] = useState([]);
    const [inputVal, setInputVal] = useState("");

    const prodUrl = "https://astroindia.azurewebsites.net/";
    const localUrl = "http://localhost:8090/";
    const uatUrl = "https://astroservice-uat.azurewebsites.net/";

    const navigate = useNavigate();

    useEffect(() => {

        dispatch({ type: actionTypes.RESET_STATE });
        dispatch({ type: actionTypes.UPDATE_HEADLINES, headlines: [] });

    }, []);

    const location = useLocation();

    const categoryMap = {
        all: 'All',
        'new-entities': 'New',
        // 'personal-reports': 'In-depth Predictions',
        'personal-reports': 'Predictions',
        'live-predictions': 'Live',
        career: 'Career',
        love: 'Love',
        numerology: 'Numerology',
        vastu: 'Vastu',
        wealth: 'Wealth',
        marriage: 'Marriage',
        'feng-shui': 'Feng Shui',
    };

    useEffect(() => {
        //let app_category = 'Personal Reports';
        let app_category = 'All';

        const app_category_params = decodeURIComponent(location.pathname.split('/')[1] || '').toLowerCase();


        const exactMatch = categoryMap[app_category_params];

        if (!!exactMatch) {
            app_category = exactMatch;
        }

        dispatch({ type: actionTypes.SET_APP_CATEGORY, app_category: app_category });

    }, [])

    useEffect(() => {
        const getEntitiesByCategory = async () => {
            //console.log(`Fetching entities for: ${app_category}`);
            const entityJSON = await axios.get(`${getUrl()}/entities`, {
                params: {
                    app_category: app_category,
                    gender: gender
                }
            });
            //console.log(entityJSON.data);
            setEntities(entityJSON.data);
        };

        if (!!app_category && app_category != "") {
            getEntitiesByCategory();
        }



    }, [app_category]);

    const EntitiesElem = entities.map((u, i) => {
        return (<div key={i}><Entity entityImageSrc={u.entityImageSrc} app_category={app_category}
            entityName={u.entityName}
            entityDescription={u.entityDescription}
            entityRating={u.entityRating}
            entityCode={u.entityCode} orders={u.orders}
            sampleQuestions={u.sampleQuestions.join(", ")} />

            <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} /></div>)
    })

    return (
        <>
            <Helmet>
                <title>AstroSky - Top AI-Powered Astrology Service</title>
                <meta
                    name="description"
                    content="Astrosky.co.in offers the top AI-powered astrology service for online predictions. Enjoy free horoscopes and consultations online. Our AI astrologer delivers personalized and accurate answers to all your concerns. Chat with AstroSky anytime for real-time astrology insights."
                />
                <meta
                    name="keywords"
                    content="Astrology AI chat, Free Astrology, Free Online Astrology, AI prediction chatbot, AI Astrologer, Free Horoscope, Free Online Horoscope, Online Horoscope, Free Astrologer, Free Online Astrologer, Astrology Online, Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, AI Astrology, Best Astrologer India, Best online astrology website in India, Vedic Astrology, Indian Astrology"
                />
            </Helmet>

            <div>
                <div className="container">
                    <div className="container__header">
                        <Header userCred={userCred} showLogin={false} />
                    </div>
                    <div className="container__body" style={{ textAlign: "center" }}>



                        <TypeAnimation sequence={[
                                    '9 million Questions Answered',
                                    3000,
                                    '100% Privacy and No Data Stored',
                                    3000,
                                    'Trusted by 4 Lakh Users',
                                    3000,
                                ]} wrapper="span" speed={10} style={{textAlign:"center", marginTop:"5%", fontSize: "1.3em", fontWeight: "450", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401" }} repeat={1} />




                        <BirthDetailsDisplayComponent dob={dob} tob={tob} city={city} />

                        {/* { (!!dob && !!tob && !! city) &&  <form className='askfollowup' style={{marginBottom:"25px"}}   
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        dispatchMessageToAstrologer(); 
                                                                }}>
                                <div className='askfollowup__input' style={{width:"90%"}}>
                                            <input value={inputVal} onChange={e=> setInputVal(e.target.value)} placeholder={'Message Astrologer  .. '}/>
                                          
                                            { inputVal.length == 0  && <ArrowCircleRightIcon style={{opacity: 0.5, fontSize:"2rem"}} onClick={dispatchMessageToAstrologer}/>}
            { inputVal.length > 0 && <ArrowCircleRightIcon style={{opacity: 1, fontSize:"2rem"}} onClick={dispatchMessageToAstrologer}/>}

                                    </div> 
                            </form>
                            } */}

                        {/* <MyFormComponent dob={dob} tob={tob} city={city} dispatchMessageToGuruji={dispatchMessageToGuruji} /> */}


                        <HorizontalScrollButtons app_category={app_category} dispatch={dispatch} horoscope={horoscope} />


                        {app_category == "Live Predictions" && <><br /><br /><LiveDashas horoscope={horoscope} /></>}

                        {app_category == "Personal Reports" && <><ReportView /></>}

                        {app_category == "Weekly Horoscope" && <><br /><br /><WeeklyHoroscopeView /></>}

                        {!Capacitor.isNativePlatform() && <div style={{ margin: "10px", color: "gray", fontSize: "1rem", fontWeight: "400" }}>


                            {/* <p>Download our App for Unlimited Free Chat</p> */}

                            {/* <TypeAnimation sequence={[
                                'Download our App for Unlimited Free Chat'
                            ]} wrapper="span" speed={10} style={{ textAlign: "center", marginTop: "5%", fontSize: "1rem", fontWeight: "400", lineHeight: "2.1rem", display: "inline-block", color: "gray" }} repeat={0} /> */}
                            <p style={{ textAlign: "center", marginTop: "5%", fontSize: "1rem", fontWeight: "400", lineHeight: "2.1rem", display: "inline-block", color: "gray" }}>Download our App for Unlimited Free Chat</p>
                            <div style={{ marginTop: "25px" }}>
                                <img style={{ width: "11rem" }} src="/icons/googlePlay.png" onClick={(_) => {
                                    //Android_App
                                    //  window.gtag('event', 'Android_App');//window.gtag('event', entity);
                                    window.gtag('event', "app_cta");
                                    window.open("https://play.google.com/store/apps/details?id=com.astroskyandroid.app")
                                }} />
                            </div></div>}


                        {EntitiesElem}

                        <Disclaimer hindi={false} />

                        <div style={{ height: "30vh" }}></div>


                        {/* </div> */}
                    </div>
                </div>
                <AppFooter home={true} more={false} />
            </div>
        </>
    );
};

export default Discover;
